import { gql } from '@apollo/client';

export const CONTAINER_GITIFY_EXTRACT_MUTATION = gql`
mutation containerGitifyExtract(
  $container: ID!){
  containerGitifyExtract(input:{
    container: $container})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_NORMALIZE_PERMISSIONS_MUTATION = gql`
mutation containerNormalizePermissions(
  $container: ID!){
  containerNormalizePermissions(input:{
    container: $container})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_DELETE_CACHE_MUTATION = gql`
mutation containerDeleteCache(
  $container: ID!){
  containerDeleteCache(input:{
    container: $container})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_STOP_MUTATION = gql`
mutation containerStop(
  $container: ID!,
  ){
  containerStop(input:{
    container: $container
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_START_MUTATION = gql`
mutation containerStart(
  $container: ID!,
  ){
    containerStart(input:{
      container: $container
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_GULP_BUILD_DEVELOPMENT_MUTATION = gql`
mutation containerGulpBuildIsDevelopment(
  $container: ID!){
  containerGulpBuild(input:{
    container: $container
    isDevelopmentBuild: true
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_GULP_BUILD_MUTATION = gql`
mutation containerGulpBuild(
  $container: ID!,
  ){
    containerGulpBuild(input:{
      container: $container,
    },
    )
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_START_CRON_MUTATION = gql`
mutation containerStartCron(
  $container: ID!){
  containerStartCron(input:{
    container: $container})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const CONTAINER_CANCEL_CRON_MUTATION = gql`
mutation containerCancelCron(
  $container: ID!){
  containerCancelCron(input:{
    container: $container})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;
