// APPLICATIONS WIDE ALERTS
export const SET_ERROR_ALERTS = 'SET_ERROR_ALERTS';
export const SET_SUCCESS_ALERTS = 'SET_SUCCESS_ALERTS';
export const SET_INFO_ALERTS = 'SET_INFO_ALERTS';
export const DISCARD_ALERT = 'DISCARD_ALERT';
export const DISCARD_ACTION_ALERT = 'DISCARD_ACTION_ALERT';

// CONFIRMATIONS
export const DELETE_NOTE_CONFIRMATION = 'DELETE_NOTE_CONFIRMATION';
export const REBUILD_CONFIRMATION = 'REBUILD_CONFIRMATION';
export const REFRESH_CONFIRMATION = 'REFRESH_CONFIRMATION';
export const START_DBA_CONFIRMATION = 'START_DBA_CONFIRMATION';
export const STOP_DBA_CONFIRMATION = 'STOP_DBA_CONFIRMATION';
export const START_INSTANCE_CONFIRMATION = 'START_INSTANCE_CONFIRMATION';
export const STOP_INSTANCE_CONFIRMATION = 'STOP_INSTANCE_CONFIRMATION';
export const GITIFY_EXTRACT_CONFIRMATION = 'GITIFY_EXTRACT_CONFIRMATION';
export const NORMALIZE_PERMISSIONS_CONFRIMATION = 'NORMALIZE_PERMISSIONS_CONFRIMATION';
export const DELETE_CACHE_CONFRIMATION = 'DELETE_CACHE_CONFRIMATION';
export const GULP_BUILD_DEVELOPMENT_CONFIRMATION = 'GULP_BUILD_DEVELOPMENT_CONFIRMATION';
export const GULP_BUILD_CONFIRMATION = 'GULP_BUILD_CONFIRMATION';
export const START_CRON_CONFRIMATION = 'START_CRON_CONFRIMATION';
export const CANCEL_CRON_CONFRIMATION = 'CANCEL_CRON_CONFRIMATION';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';
export const SET_FONT_CONFIRMATION = 'SET_FONT_CONFIRMATION';
export const BACKUP_CONFIRMATION = 'BACKUP_CONFIRMATION';
export const APPLY_REMOTE_CHANGES_CONFIRMATION = 'APPLY_REMOTE_CHANGES_CONFIRMATION';
export const STORE_LOCAL_CHANGES_CONFIRMATION = 'STORE_LOCAL_CHANGES_CONFIRMATION';
export const RESET_LOCAL_CHANGES_CONFIRMATION = 'RESET_LOCAL_CHANGES_CONFIRMATION';
export const NPM_INSTALL_CONFIRMATION = 'NPM_INSTALL_CONFIRMATION';

// NOTES
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const CLOSE_UPDATE_NOTE = 'CLOSE_UPDATE_NOTE';

// INSTANCES
export const START_INSTANCE = 'START_INSTANCE';
export const STOP_INSTANCE = 'STOP_INSTANCE';
export const REBUILD = 'REBUILD';
export const REFRESH = 'REFRESH';
export const UPDATE_INSTANCE = 'UPDATE_INSTANCE';
export const CLOSE_UPDATE_INSTANCE = 'CLOSE_UPDATE_INSTANCE';
export const BACKUP = 'BACKUP';

// FONTS
export const SET_FONT_PAYLOAD = 'SET_FONT_PAYLOAD';
export const APPLY_GOOGLE_FONT = 'APPLY_GOOGLE_FONT';
export const APPLY_GOOGLE_FONT_LOADING = 'APPLY_GOOGLE_FONT_LOADING';
export const APPLY_GOOGLE_FONT_NOT_LOADING = 'APPLY_GOOGLE_FONT_NOT_LOADING';

// CONTAINERS
export const GULP_BUILD = 'GULP_BUILD';
export const START_DBA = 'START_DBA';
export const STOP_DBA = 'STOP_DBA';
export const GITIFY_EXTRACT = 'GITIFY_EXTRACT';
export const NORMALIZE_PERMISSIONS = 'NORMALIZE_PERMISSIONS';
export const START_CRON = 'START_CRON';
export const CANCEL_CRON = 'CANCEL_CRON';

// MODX USERS MANAGEMENT
export const ACTIVATE_USERS = 'ACTIVATE_USERS';
export const DEACTIVATE_USERS = 'DEACTIVATE_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';
export const GLOBAL_ACTIVATE_USERS = 'GLOBAL_ACTIVATE_USERS';
export const GLOBAL_DEACTIVATE_USERS = 'GLOBAL_DEACTIVATE_USERS';
export const GLOBAL_REMOVE_USERS = 'GLOBAL_REMOVE_USERS';
export const MODX_USERS_MANAGEMENT_LOADING = 'MODX_USERS_MANAGEMENT_LOADING';
export const MODX_USERS_MANAGEMENT_NOT_LOADING = 'MODX_USERS_MANAGEMENT_NOT_LOADING';

// VERSION CONTROL
export const APPLY_REMOTE_CHANGES = 'APPLY_REMOTE_CHANGES';
export const STORE_LOCAL_CHANGES = 'STORE_LOCAL_CHANGES';
export const RESET_LOCAL_CHANGES = 'RESET_LOCAL_CHANGES';

// NPM
export const NPM_INSTALL = 'NPM_INSTALL';
