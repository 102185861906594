import { Dialog, Transition } from '@headlessui/react';
import {
  IconX, IconCheck, IconPlayerPause, IconAlertTriangle, IconFlame,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InstancesQuantityType } from '../../../__generated__/graphql';

interface Props {
  sidebarOpen: boolean,
  setSidebarOpen: (value: boolean) => void,
  instancesQuantity: InstancesQuantityType,
}

function MobileSidebar(props: Props) {
  const { sidebarOpen, setSidebarOpen, instancesQuantity } = props;
  const { tab } = useParams();

  const navigation = [
    {
      name: `Active (${instancesQuantity ? instancesQuantity.runningQuantity : ''})`, to: 'active', icon: IconCheck, current: true,
    },
    {
      name: `Inactive (${instancesQuantity ? instancesQuantity.exitedQuantity : ''})`, to: 'exited', icon: IconPlayerPause, current: false,
    },
    {
      name: `Failed (${instancesQuantity ? instancesQuantity.errorQuantity : ''})`, to: 'error', icon: IconAlertTriangle, current: false,
    },
    {
      name: `High RAM Usage (${instancesQuantity ? instancesQuantity.highRamUsageQuantity : ''})`, to: 'high', icon: IconFlame, current: false,
    },
  ];

  return (
    <Dialog
      as="div"
      className="fixed inset-0 flex z-40 lg:hidden"
      open={sidebarOpen}
      onClose={setSidebarOpen} // Clicking outside or pressing ESC setsSidebarOpen(false)
    >
      <div className="absolute inset-0 overflow-hidden">
        {/* -- BACKDROP -- */}
        <Transition
          appear
          show={sidebarOpen}
          unmount={false}
          as={Fragment}
          enter="transition-opacity ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 backdrop-blur-sm bg-white/10" />
        </Transition>

        <div className="fixed inset-y-0 right-0 max-w-full flex pr-16">
          {/* -- SLIDING PANEL -- */}
          <Transition
            appear
            show={sidebarOpen}
            unmount={false}
            as={Fragment}
            enter="transform transition ease-in-out duration-100"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-100"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="shadow-2xl w-screen max-w-md">
              <div className="flex h-full flex-col divide-y divide-slate-200 bg-white shadow-xl">
                <div className="h-0 flex-1 overflow-y-auto">
                  {/* Header */}
                  <div className="bg-slate-700 py-4 px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">
                        Instance Categories
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-slate-700 text-slate-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <IconX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Nav links */}
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="mt-4 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2">
                        <div className="space-y-1">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={`/dashboard/${item.to}/`}
                              onClick={() => setSidebarOpen(false)}
                              className={`
                                group flex items-center px-2 py-2 text-sm font-medium rounded-md
                                ${
                                  tab === item.to
                                    ? 'bg-slate-200 text-slate-800 font-bold'
                                    : 'text-slate-700 hover:text-slate-900 hover:bg-slate-50'
                                }
                              `}
                            >
                              <item.icon
                                className={`
                                  mr-3 h-6 w-6 flex-shrink-0
                                  ${
                                    tab === item.to
                                      ? 'text-slate-500'
                                      : 'text-slate-400 group-hover:text-slate-500'
                                  }
                                `}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition>
        </div>
      </div>

      <div className="flex-shrink-0 w-14" aria-hidden="true">
        {/* Dummy element to force sidebar to shrink to fit close icon */}
      </div>
    </Dialog>
  );
}

export default MobileSidebar;
