import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CreateInstanceForm } from '../forms';

interface Props {
  overlayState: boolean,
  setOverlayState: (value: boolean) => void,
}

function CreateInstance(props: Props) {
  const { overlayState, setOverlayState } = props;

  return (
     <Dialog
      as="div"
      className="fixed inset-0 overflow-hidden z-50"
      open={overlayState}
      onClose={setOverlayState}
    >
      <div className="absolute inset-0 overflow-hidden">
        {/* --- BACKDROP TRANSITION --- */}
        <Transition
          show={overlayState}
          as={Fragment}
          appear
          unmount={false} // Keep in DOM for exit animation
          enter="transition-opacity ease-in-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 backdrop-blur-sm bg-white/10" />
        </Transition>

        <div className="fixed inset-y-0 right-0 max-w-full flex pl-16">
          {/* --- SLIDING PANEL TRANSITION --- */}
          <Transition
            show={overlayState}
            as={Fragment}
            appear
            unmount={false} // Keep in DOM for exit animation
            enter="transform transition ease-in-out duration-100"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-100"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            {/* If your Headless UI version supports Dialog.Panel, use it */}
            <Dialog.Panel className="shadow-2xl w-screen max-w-md">
              <CreateInstanceForm setOverlayState={setOverlayState} />
            </Dialog.Panel>
          </Transition>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateInstance;
