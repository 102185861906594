/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconPlus } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PaginatedIntanceType, UserType } from '../../__generated__/graphql';
import { useAppSelector, useAppDispatch } from '../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../layout';
import { ReduxAlert } from '../../layout/alerts';
import { discardAlert } from '../../redux/alertSlice';
import ActiveDBAAlert from './ActiveDBAAlert';
import ConfirmationModal from './ConfirmationModal';
import InstancesTable from './InstancesTable';
import { MobileInstancesList } from './mobile';
import { CreateInstance, InstanceCard } from './overlays';
import PinnedInstances from './PinnedInstances';

interface Props {
  items: PaginatedIntanceType,
  user: UserType,
  loading: boolean,
}

function Dashboard(props: Props) {
  const { items, loading, user } = props;
  const [currentInstanceId, setCurrentInstanceId] = useState<string | null>(null);
  const [openCreateOverlay, setOpenCreateOverlay] = useState(false);
  const [openDetailsOverlay, setOpenDetailsOverlay] = useState(false);
  const { tab, instanceId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const alertsState = useAppSelector(state => state.alert);

  const closeInstanceDetails = () => {
    setOpenDetailsOverlay(false);
    navigate(`/dashboard/${tab}/`);
  };

  useEffect(() => {
    setCurrentInstanceId(instanceId as string);
  }, [instanceId]);

  useEffect(() => {
    if (currentInstanceId) {
      setOpenDetailsOverlay(true);
    }
  }, [currentInstanceId]);

  const instances = items ? items.objects : [];
  return (
    <>
      <div className="border-b border-slate-200 px-4 py-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2">
          <div className="flex items-center">
            <h1 className="text-lg font-medium leading-6 text-slate-900 sm:truncate">Overview</h1>
          </div>
          {user && user.isSuperuser && (
            <div className="justify-self-end flex mt-0 sm:ml-4">
              <button
                type="button"
                onClick={() => setOpenCreateOverlay(true)}
                className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-500 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:order-1 sm:ml-3"
              >
                <IconPlus className="mr-1 h-5 w-5 " aria-hidden="true" />
                Create Instance
              </button>
            </div>
          )}
        </div>
      </div>
      <ActiveDBAAlert />
      <div className="px-4 mt-6 sm:px-6 lg:px-8">
        <PinnedInstances />
      </div>
      <div className="mt-10 lg:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-slate-500 text-xs font-medium uppercase tracking-wide">
            {tab}
            {' '}
            Instances
          </h2>
        </div>
      </div>
      {
        items && items?.objects?.length
          ? (
            <>
              <div>
                {alertsState.alert
                  && !alertsState.alert.instanceId
                  && (
                    <ReduxAlert
                      key={alertsState.alert.id}
                      alertType={alertsState.alert.alertType}
                      messageList={alertsState.alert.messages}
                      handleClose={() => { dispatch(discardAlert()); }}
                    />
                  )}
              </div>
              {/* Projects list (only on smallest breakpoint) */}
              <div className="lg:hidden">
                <MobileInstancesList items={instances as InstanceType<any>[]} />
              </div>

              {/* Projects table (small breakpoint and up) */}
              <div className="hidden mt-8 lg:block">
                <InstancesTable items={instances as InstanceType<any>[]} />
              </div>

              <Pagination
                page={items.page as number}
                hasNext={items.hasNext as boolean}
                hasPrev={items.hasPrev as boolean}
                elidedPageRange={items.elidedPageRange as string[]}
                omitBorder
              />
            </>
          )
          : (
            <div className="flex h-20 lg:h-96 justify-center items-center font-bold text-3xl lg:text-4xl text-slate-500">{loading ? <LoadingIndicator className="flex justify-center" /> : 'Nothing here'}</div>
          )
      }
      <CreateInstance
        overlayState={openCreateOverlay}
        setOverlayState={setOpenCreateOverlay}
      />
      <div className="hidden sm:block">
        <InstanceCard
          instanceId={currentInstanceId as string}
          overlayState={openDetailsOverlay}
          closeInstanceDetails={closeInstanceDetails}
        />
      </div>
      <ConfirmationModal />
    </>
  );
}

export default Dashboard;
